import parsePhoneNumber from "libphonenumber-js";
/**
 * @module forms/FormValidators
 * @description Functions that validate form inputs.
 * <br>All of them must take the input as the first parameter.
 * <br> All functions return false if the input is valid and an error message as a string if the input is not valid.
 */
export default {
    /**
     * Validates if an input is empty
     * @param {string} input - Input to validate
     * @param {boolean} boolOnly - If set to true, returns if value is valid or not instead of a message
     * @returns {boolean | string}
     */
    required: function required(input, boolOnly = false) {
        const message = "Field can't be empty.";
        // If falsy value
        if (!input) {
            return boolOnly ? false : message;
        }
        // If empty (works for objects, arrays, etc.)
        if (Object.keys(input).length <= 0) {
            return boolOnly ? false : message;
        }
        return boolOnly;
    },
    /**
     * Validates if an input has minimum characters
     * @param {string} input - Input to validate
     * @param {number} min - Minimum number of characters
     * @returns {boolean | string}
     */
    minChar: function minChar(input, min) {
        min = Number(min);
        if (input.length < min)
            return `Field must have at least ${min} characters.`;
        else
            return false;
    },
    /**
     * Validates if an input has maximum characters
     * @param {string} input - Input to validate
     * @param {number} max - Maximum number of characters
     * @returns {boolean | string}
     */
    maxChar: function maxChar(input, max) {
        max = Number(max);
        if (input.length > max)
            return `Field must have no more than ${max} characters.`;
        else
            return false;
    },
    /**
     * Validates if an input is a valid email.
     * @param {string} input - Input to validate
     * @returns {boolean | string} false, in case no errors were found, else an error message.
     */
    isEmail: function (input) {
        const message = "Field must be a valid email address.";
        if (!input)
            return false;
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reg.test(input)) {
            return message;
        }
        else {
            return false;
        }
    },
    /**
     * Validates if an input is a valid email. Checks if the email is within the list of domains.
     * @param {string} input - Input to validate
     * @param {string} domains - Comma separated array of the valid domains for the array.
     * @returns {boolean | string} false, in case no errors were found, else an error message.
     */
    isEmailWithinDomains: function (email, domains) {
        const validationResult = this.isEmail(email);
        if (!validationResult) {
            if (domains.length > 0 &&
                !domains.split(",").some(d => email.includes(d))) {
                return "The email should be within the list of allowed domains for your organization.";
            }
            return false;
        }
        return validationResult;
    },
    hasAllowedDomain: function (input, domains) {
        if (!(domains instanceof Array)) {
            domains = [domains];
        }
        if (!input || !input.includes('@')) {
            return false;
        }
        // Extract the domain part of the email and ensure it is defined
        const emailDomain = input.split('@').pop();
        if (!emailDomain) {
            return false;
        }
        // Check if the email domain is in the list of allowed domains
        if (!domains.some((domain) => emailDomain.endsWith(domain))) {
            return "Invalid domain. Please use a valid email address";
        }
        return false;
    },
    validateNewPassword: function (input, confirmInput) {
        if (input !== confirmInput)
            return `Passwords do not match.`;
        else
            return false;
    },
    isPhoneNumber: function (input) {
        if (!input)
            return false;
        const regex = new RegExp("[a-zA-Z]");
        try {
            parsePhoneNumber(input);
            if (regex.test(input)) {
                return "Field must be a valid phone number.";
            }
            return false;
        }
        catch (error) {
            if (error.message) {
                switch (error.message) {
                    case "INVALID_COUNTRY":
                        return "Country code is not valid.";
                    case "TOO_SHORT":
                        return "Phone number is too short.";
                    case "NOT_A_NUMBER":
                    default:
                        return "Field must be a valid phone number.";
                }
            }
        }
    }
};
