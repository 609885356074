import { defineComponent } from "vue";
import { AxionInputBase } from "../AxionInputBase";
export default defineComponent({
    name: "AxionInputCheckbox",
    components: { AxionInputBase },
    props: {
        value: {
            required: true,
            type: null
        },
        inputClass: {
            type: Array,
            required: false,
            default: () => []
        }
    }
});
